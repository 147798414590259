<template>
	<v-dialog v-model="dialog" width="800">
		<template v-slot:activator="{ on, attrs }">
			<v-btn color="primary" dark v-bind="attrs" v-on="on" text>{{nominations.length }}</v-btn>
		</template>

		<v-card>
			<v-card-title class="text-h4 primary white--text" dark>{{ productName }}</v-card-title>
			<v-card-text>
				<v-card elevation="8" v-for="nomination in nominations" class="mt-5">
					<v-card-text v-if="nomination.answers.length > 0">
						<v-list>
							<v-list-item v-for="answer in nomination.answers">
								<v-row>
									<v-col cols="12">Q: {{answer.question }} <br> A: {{answer.answer }}</v-col>
								</v-row>
							</v-list-item>
						</v-list>
					</v-card-text>
					<v-divider v-if="nomination.answers.length > 0"></v-divider>
					<v-card-actions>
						<v-row>
							<v-col cols="12">
								{{ nomination.nominator_first_name }} {{ nomination.nominator_last_name }} <{{ nomination.nominator_email }}>
							</v-col>
							<v-col cols="6">
								Keep informed:
								<v-icon v-if="nomination.keep_informed" color="success">{{ $icons.check }}</v-icon>
								<v-icon v-else color="danger"> {{ $icons.close }}</v-icon>
							</v-col>
							<v-col cols="6">
								Marketing consent:
								<v-icon v-if="nomination.marketing_consent" color="success">{{ $icons.check }}</v-icon>
								<v-icon v-else color="error"> {{ $icons.close }}</v-icon>
							</v-col>
						</v-row>
					</v-card-actions>
				</v-card>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click="dialog = false">close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "NominationsDialog",
	props: {
		nominations: {
			type: Array,
			required: true,
		},
		productName: {
			type: String,
			required: true,
		}
	},
	data: () => ({
		dialog: false,
	}),
	methods: {
		toggle() {
			this.dialog = !this.dialog
		}
	}
}
</script>